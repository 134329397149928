import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import bannerImg from '../../assets/images/UI-design.png';
import Lottie from 'react-lottie';

import animationData from '../../assets/lotties/website-scroll.json';




const MainBanner = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="free-webiste-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Site web gratuit</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Nous développons votre site web gratuitement !</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Vous avez besoin d'un premier site web pour mettre en avant votre activité ? Nous prenons en charge le développement et vous ne payez que l'hébergement.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        Contactez-nous maintenant!
                                        <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            {/* <div className="banner-wrapper-image">
                                <img src={bannerImg} alt="Site internet gratuit" title="Site web gratuit"/>
                            </div> */}
                            <Lottie
                                options={defaultOptions}
                                height="100%"
                                width="100%"
                            />
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner
