import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie';
import like from '../../assets/images/icons/like.png';
import atomIcon from '../../assets/images/Yummy-Technologies-icon-little.png'
import saas from "../../assets/images/web-mockup.png"
// import SecurityDevData from '../../assets/lotties/web-development.json';
// import DeveloperData from '../../assets/lotties/business-startup.json';


const Services = () => {
    const [SecurityDevData, setSecurityDevData] = useState();
    const [DeveloperData, setDeveloperData] = useState();
    const [YogaData, setYogaData] = useState();
    const [IsSecurityDevVisible, setIsSecurityDevVisible] = useState(false);
    const [IsDeveloperVisible, setIsDeveloperVisible] = useState(false);
    const [IsYogaVisible, setIsYogaVisible] = useState(false);

    const listenToScroll = () => {

        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > 700) {
            if (!IsSecurityDevVisible) {
                setIsSecurityDevVisible(true);
            }    // to limit setting state only the first time         

        }

        if (winScroll > 1000) {
            if (!IsDeveloperVisible) {
                setIsDeveloperVisible(true);
            }      // to limit setting state only the first time         

        }

        if (winScroll > 1300) {
            if (!IsYogaVisible) {
                setIsYogaVisible(true);
            }      // to limit setting state only the first time         

        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {
        import('../../assets/lotties/man-security.json').then(setSecurityDevData);
        import('../../assets/lotties/office-developer.json').then(setDeveloperData);
        import('../../assets/lotties/yoga.json').then(setYogaData);
    }, []);


    const securityDevAnimation = {
        loop: true,
        autoplay: true,
        animationData: SecurityDevData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const developerAnimation = {
        loop: true,
        autoplay: true,
        animationData: DeveloperData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const yogaAnimation = {
        loop: true,
        autoplay: true,
        animationData: YogaData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            {IsDeveloperVisible && <Lottie
                                options={developerAnimation}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>


                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Développement sur mesure</span>
                            <h2>Développé avec <img src={like} alt="Développé avec amour" title="Développé avec amour" /> par des développeurs belges!</h2>
                            <p>Pas de robots ici, ce sont nos développeurs qui se chargent en personne de développer votre site Internet. </p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Développé sur mesure</h4>
                                <p>Sur base des informations que vous fournissez, nous développons un site internet à votre image.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Révisions</h4>
                                <p>Vous avez le droit de demander à modifier certains aspects de votre site. </p>
                            </div>
                        </div>
                    </div>


                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Hébergement rapide & sécurisé</span>
                            <h2>Un hébergement sécurisé à 49€/mois, 0€ de frais de démarrage!</h2>
                            <p>Un simple abonnement pour l'hébergement, c'est tout ce que vous devez payer pour profiter de votre super site Internet. L'hébergement comprend notamment :</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Votre nom de domaine</span></li>
                                <li><span><i className='bx bx-check'></i> Une connexion sécurisée (https)</span></li>
                                <li><span><i className='bx bx-check'></i> Une connexion rapide</span></li>
                                {/* <li><span><i className='bx bx-check'></i> Site optimisé</span></li>
                                <li><span><i className='bx bx-check'></i> Mises à jour d'avancement</span></li>
                                <li><span><i className='bx bx-check'></i> Révisions</span></li> */}
                            </ul>
                        </div>
                    </div>


                    <div className="overview-image">
                        <div className="image">
                            {IsSecurityDevVisible && <Lottie
                                options={securityDevAnimation}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* <div className="section-title">
                    <span className="sub-title">
                        <img src={atomIcon} width="5%" alt="features" />
                        Garantie</span>
                    <h2>Garantit 30 jours satisfait ou remboursé!</h2>
                    <p>Votre site ne vous plait pas? Pas de problème. On vous rembourse votre abonnement et vous n'aurez pas dépensé un seul cent!</p>
                </div> */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                        {IsYogaVisible && <Lottie
                                options={yogaAnimation}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Restez zen</span>
                            <h2>Garantit 90 jours satisfait ou remboursé!</h2>
                            <p>Votre site ne vous plait pas? Pas de problème. On vous rembourse votre abonnement et vous n'aurez pas dépensé un seul cent!</p>

                            {/* <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Recommender systems</span></li>
                                <li><span><i className='bx bx-check'></i> Demand prediction</span></li>
                                <li><span><i className='bx bx-check'></i> Omnichannel analytics</span></li>
                                <li><span><i className='bx bx-check'></i> Lead generation</span></li>
                                <li><span><i className='bx bx-check'></i> Dedicated Developers</span></li>
                                <li><span><i className='bx bx-check'></i> 24/7 Support</span></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services