import React from 'react'
import ReactWOW from 'react-wow'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/launch.png'
import emailjs from 'emailjs-com';

const ContactForm = () => {

    const sendContactEmail = (e) => {
        e.preventDefault();
        console.log("sendEmail...");

        emailjs.sendForm('service_8j19wyh', 'template_ozce214', e.target, 'user_Io6hQDXLobAbA5jUwZICt')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        emailjs.sendForm('service_8j19wyh', 'template_hx8ibup', e.target, 'user_Io6hQDXLobAbA5jUwZICt')
            .then((resultTwo) => {
                console.log(resultTwo.text);
            }, (errorTwo) => {
                console.log(errorTwo.text);
            });
        e.target.reset();
        window.open("https://calendar.google.com/calendar/u/0/selfsched?sstoken=UVBJekJycURFRU9jfGRlZmF1bHR8YTBkODA4MjI3ZDc3ZTdiZTMzMDJlYzk5YzFlNWY2MzI", "_blank");
    }

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" />
                        Nous contacter
                    </span>
                    <h2>Réservez une consultation gratuite de 30 minutes!</h2>
                    <p>Discutons ensemble de votre activité et du site web qui la mettra en avant. </p>

                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="contact-image">
                                <img src={contact} alt="contact" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={(e) => {
                                sendContactEmail(e);
                                alert("Merci de nous avoir contacté! \nNous vous répondrons dans les plus brefs délais.");
                            }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Votre nom" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Votre adresse email" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone" className="form-control" required placeholder="Votre numéro de téléphone" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="company" className="form-control" required placeholder="Le nom de votre entreprise/activité" />
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Écrivez votre message..." />
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i>
                                            Réservez votre appel <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    )
}

export default ContactForm