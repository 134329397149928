import React from "react"
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import MainBanner from "../components/FreeWebsite/MainBanner"
import FeaturedServices from "../components/DigitalMarketingAgency/FeaturedServices"
import AboutService from "../components/FreeWebsite/AboutService"
import Services from "../components/FreeWebsite/Services"
import ContactForm from "../components/FreeWebsite/ContactForm"

import CookieConsent from "react-cookie-consent";


const Index = () => {

    return (
        <Layout>
            <NavbarTwo />
            <MainBanner />
            {/* <Partner /> */}
            <AboutService />
            <Services />
            <ContactForm />
            {/* <FunFacts /> */}
            <FeaturedServices />
            {/* <Testimonials /> */}
            {/* <Pricing /> */}  
            {/* <OurBlog /> */}
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="D'accord"
                overlay={false}
                style={{ background: "#202A45" }}
                buttonStyle={{ color: "white", background: "#05D9FE", fontSize: "15px" }}
                expires={150}
            >Ce site utilise des cookies pour améliorer votre expérience utilisateur.</CookieConsent>
        </Layout>
    );
}

export default Index