import React from 'react'
import ReactWOW from 'react-wow'
import atomIcon from '../../assets/images/Yummy-Technologies-icon-little.png'
import webMockup from '../../assets/images/web-mockup.png'

const AboutService = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <br /><br /><br />
                    <span className="sub-title">
                        <img src={atomIcon} width="5%" alt="features" />
                        Comment ça marche</span>
                    <h2>On s'occupe du développement, vous ne payez que l'hébergement</h2>
                    <p>Plus besoin de débourser 1000€ cash pour votre premier site web, nous developpons rapidement le site qui mettra en avant votre activité sans creusé de trou dans votre trésorerie! </p>
                </div>

                <div className="overview-box">
                    

                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Votre site gratuit</span>
                            <h2>Que comprend votre site gratuit? (Valeur tarifaire standard: 840€) </h2>
                            <p>Nous vous développons un site web comprenant tout ce qu'il vous faut pour débuter votre succes story sur le web!</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Votre site vitrine sur mesure</h4>
                                <p>Nous réalisons votre site vitrine reprenant toutes les informations que vous nous aurez transmises à propos de votre entreprise.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Un formulaire de contact</h4>
                                <p>Nous y ajoutons un formulaire de contact par mail pour que vos clients puissent vous contacter depuis votre site web.</p>
                            </div>
                        </div>
                    </div>


                    <div className="overview-image">
                        <div className="image">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="about-img">
                                    <img src={webMockup} alt="exemple de Site internet" title="Exemple de site gratuit" />
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutService